import { USER_ROLE } from 'utils/enums/user-role.enum';
import { getFromLocalStorage } from 'utils/helpers/local-storage/localStorage';

export function handleRedirectRoute(): string {
	const access_token = getFromLocalStorage('access_token');
	const role = getFromLocalStorage('role');

	if (access_token) {
		if (role === USER_ROLE.WRITER || role === USER_ROLE.SOURCER) return '/writer/my-stories';

		if (role === USER_ROLE.ADMIN) return '/admin/content';

		if (role === USER_ROLE.MODERATOR) return '/admin/requests?active=contracts&query=';
	}

	return '/auth/log-in';
}
